import React, {Fragment} from 'react';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Demos from './demos';
import Demos2 from './demos2';
import Footer from './footer';
import Header from './header';
import Hero from './hero';
import './style.scss'

const PreviewPage =() => {
    return(
        <Fragment>
           <Header/>
           <Hero/>
           <Demos/>
           <Demos2/>
           <Footer/>
           <Scrollbar/>
        </Fragment>
    )
};
export default PreviewPage;